import * as React from "react"
import Layout from "../components/common/layout"
import { Link } from "gatsby"
import { Separator } from "../components/common/styledComponentsHelper"

const PrivacyHeadlineStyle = "text-uppercase text-bold fw-bold"
const PrivacyHeadlineStyleH3 = "text-bold"

export const Head = () => (
  <>
    <title>Datenschutzerklärung | Fachhandwerk360</title>
    <meta
      name="facebook-domain-verification"
      content="xooo742lkxtkqa24ish1wyf2ppfc4e"
    />
  </>
)

const Datenschutzerklaerung = () => (
  <Layout>
    <div style={{ marginTop: "85px" }}>
      <Separator />
      <div className="container">
        <h1 className={PrivacyHeadlineStyle}>DATENSCHUTZERKLÄRUNG</h1>
        <h2 className={PrivacyHeadlineStyle}>Allgemeine Hinweise</h2>
        <p>
          Die folgenden Hinweise geben einen einfachen Überblick darüber, was
          mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website
          besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
          persönlich identifiziert werden können. Ausführliche Informationen zum
          Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
          Datenschutzerklärung.
        </p>
        <h2 className={PrivacyHeadlineStyle}>
          Datenerfassung auf unserer Website
        </h2>
        <h3 className={PrivacyHeadlineStyleH3}>
          Wer ist verantwortlich für die Datenerfassung auf dieser Website?
        </h3>
        <p>
          Die Datenverarbeitung auf dieser Website erfolgt durch den
          Websitebetreiber. Dessen Kontaktdaten können Sie dem{" "}
          <Link to="/impressum">Impressum</Link> dieser Website entnehmen.
        </p>
        <h3 className={PrivacyHeadlineStyleH3}>Wie erfassen wir Ihre Daten?</h3>
        <p>
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
          mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein
          Kontaktformular eingeben. Andere Daten werden automatisch beim Besuch
          der Website durch unsere IT-Systeme erfasst. Das sind vor allem
          technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit
          des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch,
          sobald Sie unsere Website betreten.
        </p>
        <h3 className={PrivacyHeadlineStyleH3}>Wofür nutzen wir Ihre Daten?</h3>
        <p>
          Im Rahmen der Veranstaltungsanmeldung willigen Sie ein, dass die
          übermittelten Daten (Name, Telefon, E-Mail) durch die Fachhandwerk360
          Dreier & Herber GbR (Bestands-, Nutzungs- und Inhaltsdaten) erhoben
          und gespeichert werden. Darüber hinaus willigen Sie ein, dass die
          Daten der Teilnehmer (Name, Telefon, E-Mail) für eine telefonische
          Ansprache zu produktbezogenen Marketing- und Vertriebszwecken an
          unseren Veranstaltungspartner Deine Baustoffe GmbH & Co. KG
          weitergegeben werden. Sie können ihre Einwilligung jederzeit über{" "}
          <a href="mailto:info@fachhandwerk360.de">info@fachhandwerk360.de</a>{" "}
          widerrufen.
        </p>
        <p>
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
          der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
          Nutzerverhaltens verwendet werden.
        </p>
        <h3 className={PrivacyHeadlineStyleH3}>
          Welche Rechte haben Sie bezüglich Ihrer Daten?
        </h3>
        <p>
          Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
          Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
          erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung
          oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren
          Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im
          Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen
          ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
        </p>
        <h2 className={PrivacyHeadlineStyle}>
          Plugins und Tools von Drittanbietern
        </h2>
        <p>
          Alle standardmäßig verwendeten Plugins, Tools und Werkzeuge sind mit
          einem Stern (*) gekennzeichnet. Tools ohne Stern werden nur optional
          beziehungsweise nur zeitlich begrenzt verwendet.
          <br /> Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch
          ausgewertet werden. Das geschieht vor allem mit Cookies und mit
          sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens
          erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen
          zurückverfolgt werden. Sie können dieser Analyse widersprechen oder
          sie durch die Nichtbenutzung bestimmter Tools verhindern. Details
          hierzu entnehmen Sie unserer Datenschutzerklärung unter der
          Überschrift "Plugins, Tools und Werbung". <br />
          Sie können dieser Analyse widersprechen. Über die
          Widerspruchsmöglichkeiten werden wir Sie in dieser
          Datenschutzerklärung informieren.
        </p>
        <h2 className={PrivacyHeadlineStyle}>
          Allgemeine Hinweise und Pflicht­informationen
        </h2>
        <h3 className={PrivacyHeadlineStyleH3}>Datenschutz</h3>
        <p>
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
          sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
          entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung.
          <br />
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
          Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
          persönlich identifiziert werden können. Die vorliegende
          Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
          sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das
          geschieht.
          <br />
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
          der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
          lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
          möglich.
        </p>
        <h3 className={PrivacyHeadlineStyleH3}>
          Hinweis zur verantwortlichen Stelle
        </h3>
        <p>
          Die verantwortliche Stelle für die Datenverarbeitung auf dieser
          Website ist der Betreiber beziehungsweise Inhaber dieser Website wie
          im <Link to="/impressum">Impressum</Link> genannt.
          <br />
          Verantwortliche Stelle ist die natürliche oder juristische Person, die
          allein oder gemeinsam mit anderen über die Zwecke und Mittel der
          Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen
          o. Ä.) entscheidet.
        </p>
        <h3 className={PrivacyHeadlineStyleH3}>
          Widerruf Ihrer Einwilligung zur Datenverarbeitung
        </h3>
        <p>
          Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
          Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
          jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail
          an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
          Datenverarbeitung bleibt vom Widerruf unberührt.
        </p>
        <h3 className={PrivacyHeadlineStyleH3}>
          Beschwerderecht bei der zuständigen Aufsichtsbehörde
        </h3>
        <p>
          Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein
          Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige
          Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der
          Landesdatenschutzbeauftragte des Bundeslandes, in dem unser
          Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten
          sowie deren Kontaktdaten können folgendem Link entnommen werden:
          <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.">
            https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
          </a>
        </p>
        <h3 className={PrivacyHeadlineStyleH3}>
          SSL- bzw. TLS-Verschlüsselung
        </h3>
        <p>
          Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
          Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder
          Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw.
          TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
          daran, dass die Adresszeile des Browsers von “http://” auf “https://”
          wechselt und an dem Schloss-Symbol in Ihrer Browserzeile. Wenn die
          SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie
          an uns übermitteln, nicht von Dritten mitgelesen werden.
        </p>
        <h3 className={PrivacyHeadlineStyleH3}>Auskunft, Sperrung, Löschung</h3>
        <p>
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
          das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
          personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck
          der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung
          oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
          personenbezogene Daten können Sie sich jederzeit unter der im
          Impressum angegebenen Adresse an uns wenden.
        </p>
        <h3 className={PrivacyHeadlineStyleH3}>
          Widerspruch gegen Werbe-Mails
        </h3>
        <p>
          Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
          Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
          Werbung und Informationsmaterialien wird hiermit widersprochen. Die
          Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
          Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
          Spam-E-Mails, vor.
        </p>
        <h3 className={PrivacyHeadlineStyleH3}>Datenschutzbeauftragter</h3>
        <p>
          Auf Grund der gesetzlichen Vorgaben müssen wir keinen
          Datenschutzbeauftragten benennen. Bitte wenden Sie sich wegen aller
          datenschutzrechtlichen Belange an die oben genannte verantwortliche
          Stelle.
        </p>
        <h2 className={PrivacyHeadlineStyle}>
          Datenerfassung auf unserer Website
        </h2>
        <h3 className={PrivacyHeadlineStyleH3}>Cookies</h3>
        <p>
          Die Internetseiten verwenden teilweise sogenannte Cookies. Cookies
          richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.
          Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
          sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem
          Rechner abgelegt werden und die Ihr Browser speichert. Die meisten der
          von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie
          werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies
          bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen. Diese
          Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch
          wiederzuerkennen. <br />
          Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
          Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
          Annahme von Cookies für bestimmte Fälle oder generell ausschließen
          sowie das automatische Löschen der Cookies beim Schließen des Browsers
          aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
          dieser Website eingeschränkt sein. Cookies, die zur Durchführung des
          elektronischen Kommunikationsvorgangs oder zur Bereitstellung
          bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion)
          erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO
          gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an
          der Speicherung von Cookies zur technisch fehlerfreien und optimierten
          Bereitstellung seiner Dienste. Soweit andere Cookies (z.B. Cookies zur
          Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in
          dieser Datenschutzerklärung gesondert behandelt.
        </p>
        <h3 className={PrivacyHeadlineStyleH3}>Server-Log-Dateien</h3>
        <p>
          Der Provider der Seiten erhebt und speichert automatisch Informationen
          in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
          übermittelt. Dies sind:
          <ul>
            <li className="list-style-check">Browsertyp und Browserversion</li>
            <li className="list-style-check">verwendetes Betriebssystem</li>
            <li className="list-style-check">Referrer URL</li>
            <li className="list-style-check">
              Hostname des zugreifenden Rechners
            </li>
            <li className="list-style-check">Uhrzeit der Serveranfrage</li>
            <li className="list-style-check">IP-Adresse</li>
          </ul>
          Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
          vorgenommen. Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1
          lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines
          Vertrags oder vorvertraglicher Maßnahmen gestattet.
        </p>
        <h3 className={PrivacyHeadlineStyleH3}>Kontaktformular</h3>
        <p>
          Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
          Angaben aus dem Anfrageformular inklusive der von Ihnen dort
          angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
          Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
          nicht ohne Ihre Einwilligung weiter. Die Verarbeitung der in das
          Kontaktformular eingegebenen Daten erfolgt somit ausschließlich auf
          Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können
          diese Einwilligung jederzeit widerrufen. Dazu reicht eine formlose
          Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf
          erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
          Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
          uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
          Speicherung widerrufen oder der Zweck für die Datenspeicherung
          entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
          Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen
          – bleiben unberührt.
        </p>
        <h2 className={PrivacyHeadlineStyle}>Plugins, Tools und Werbung</h2>
        <h3 className={PrivacyHeadlineStyleH3}> Google Analytics</h3>
        <p>
          Diese Website nutzt Funktionen des Webanalysedienstes Google
          Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway,
          Mountain View, CA 94043, USA. Google Analytics verwendet so genannte
          "Cookies". Das sind Textdateien, die auf Ihrem Computer gespeichert
          werden und die eine Analyse der Benutzung der Website durch Sie
          ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre
          Benutzung dieser Website werden in der Regel an einen Server von
          Google in den USA übertragen und dort gespeichert.
          <br />
          Die Speicherung von Google-Analytics-Cookies erfolgt auf Grundlage von
          Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
          Interesse an der Analyse des Nutzerverhaltens, um sein Webangebot zu
          optimieren.
        </p>
        <h3 className={PrivacyHeadlineStyleH3}> Facebook Pixel</h3>
        <p>
          Diese Website nutzt zur Konversionsmessung das Besucheraktions-Pixel
          von Facebook. Anbieter dieses Dienstes ist die Facebook Ireland
          Limited, 4 Grand Canal Square, Dublin 2, Irland. Die erfassten Daten
          werden nach Aussage von Facebook jedoch auch in die USA und in andere
          Drittländer übertragen. So kann das Verhalten der Seitenbesucher
          nachverfolgt werden, nachdem diese durch Klick auf eine
          Facebook-Werbeanzeige auf die Website des Anbieters weitergeleitet
          wurden. Dadurch können die Wirksamkeit der Facebook-Werbeanzeigen für
          statistische und Marktforschungszwecke ausgewertet werden und
          zukünftige Werbemaßnahmen optimiert werden. Die erhobenen Daten sind
          für uns als Betreiber dieser Website anonym, wir können keine
          Rückschlüsse auf die Identität der Nutzer ziehen. Die Daten werden
          aber von Facebook gespeichert und verarbeitet, sodass eine Verbindung
          zum jeweiligen Nutzerprofil möglich ist und Facebook die Daten für
          eigene Werbezwecke, entsprechend der
          Facebook-Datenverwendungsrichtlinie verwenden kann. Dadurch kann
          Facebook das Schalten von Werbeanzeigen auf Seiten von Facebook sowie
          außerhalb von Facebook ermöglichen. Diese Verwendung der Daten kann
          von uns als Seitenbetreiber nicht beeinflusst werden. Die Nutzung von
          Facebook-Pixel erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO.
          Der Websitebetreiber hat ein berechtigtes Interesse an effektiven
          Werbemaßnahmen unter Einschluss der sozialen Medien. Sofern eine
          entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung
          zur Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich
          auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
          jederzeit widerrufbar. In den Datenschutzhinweisen von Facebook finden
          Sie weitere Hinweise zum Schutz Ihrer Privatsphäre:
          https://de-de.facebook.com/about/privacy/. Sie können außerdem die
          Remarketing-Funktion „Custom Audiences“ im Bereich Einstellungen für
          Werbeanzeigen unter
          https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
          deaktivieren. Dazu müssen Sie bei Facebook angemeldet sein. Wenn Sie
          kein Facebook Konto besitzen, können Sie nutzungsbasierte Werbung von
          Facebook auf der Website der European Interactive Digital Advertising
          Alliance deaktivieren:
          http://www.youronlinechoices.com/de/praferenzmanagement/.
        </p>
        <h3 className={PrivacyHeadlineStyleH3}>Statcounter*</h3>
        <p>
          Diese Website nutzt Funktionen des Webanalysedienstes Statcounter.
          Anbieter ist die StatCounter, Guinness Enterprise Centre, Taylor's
          Lane, Dublin 8, Ireland. Statcounter verwendet so genannte "Cookies".
          Das sind Textdateien, die auf Ihrem Computer gespeichert werden und
          die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die
          durch das Cookie erzeugten Informationen über Ihre Benutzung dieser
          Website werden in der Regel an einen Server von Statcounter nach
          Irland übertragen und dort gespeichert. Personenbezogene Daten werden
          jedoch nicht verwaltet.
          <br />
          Die Speicherung von Statcounter-Cookies erfolgt auf Grundlage von Art.
          6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
          Interesse an der Analyse des Nutzerverhaltens, um sein Webangebot zu
          optimieren.
        </p>
        <h3 className={PrivacyHeadlineStyleH3}>IP-Anonymisierung*</h3>
        <p>
          Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert.
          Dadurch wird Ihre IP-Adresse innerhalb von Mitgliedstaaten der
          Europäischen Union oder in anderen Vertragsstaaten des Abkommens über
          den Europäischen Wirtschaftsraum vor der Übermittlung in die USA
          gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an Server der
          Analysedienste übertragen und dort gekürzt. Im Auftrag des Betreibers
          dieser Website wird der Analysedienst diese Informationen benutzen, um
          Ihre Nutzung der Website auszuwerten, um Reports über die
          Websiteaktivitäten zusammenzustellen und um weitere mit der
          Websitenutzung und der Internetnutzung verbundene Dienstleistungen
          gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen des
          Analysedienstes von Ihrem Browser übermittelte IP-Adresse wird nicht
          mit anderen Daten zusammengeführt.
        </p>
        <h3 className={PrivacyHeadlineStyleH3}>Browser Plugin</h3>
        <p>
          Sie können die Speicherung der Cookies durch eine entsprechende
          Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
          darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
          Funktionen dieser Website vollumfänglich werden nutzen können. Sie
          können darüber hinaus die Erfassung der durch den Cookie erzeugten und
          auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse)
          an Google sowie die Verarbeitung dieser Daten durch Google verhindern,
          indem Sie das unter dem folgenden Link verfügbare Browser-Plugin
          herunterladen und installieren:{" "}
          <a href="https://tools.google.com/dlpage/gaoptout?hl=de.">
            https://tools.google.com/dlpage/gaoptout?hl=de
          </a>
          .
        </p>
        <h3 className={PrivacyHeadlineStyleH3}>
          Widerspruch gegen Datenerfassung
        </h3>
        <p>
          Sie können die Erfassung Ihrer Daten durch Google Analytics
          verhindern, indem Sie auf folgenden Link klicken. Es wird ein
          Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen
          Besuchen dieser Website verhindert: Google Analytics deaktivieren.
          <br />
          Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
          finden Sie in der Datenschutzerklärung von Google:{" "}
          <a href="https://support.google.com/analytics/answer/6004245?hl=de">
            https://support.google.com/analytics/answer/6004245?hl=de
          </a>
          .
          <br />
          Mehr Informationen zum Umgang mit Nutzerdaten bei Statcounter finden
          Sie in der Datenschutzerklärung von Statcounter:{" "}
          <a href="https://statcounter.com/about/legal/">
            https://statcounter.com/about/legal/
          </a>
          .
        </p>
        <h3 className={PrivacyHeadlineStyleH3}>Auftrags­daten­ver­arbeitung</h3>
        <p>
          Wir haben mit Google und Statcounter einen Vertrag zur
          Auftragsdatenverarbeitung abgeschlossen und setzen die strengen
          Vorgaben der deutschen Datenschutzbehörden bei der Nutzung vollständig
          um.
          <ul>
            <li className="list-style-check">
              Ich willige ein, dass meine im Rahmen der Veranstaltung
              übermittelten Daten (Name, Telefon, E-Mail) durch die
              Fachhandwerk360 Dreier & Herber GbR (Bestands-, Nutzungs- und
              Inhaltsdaten) erhoben und gespeichert werden
            </li>
          </ul>
        </p>
        <h3 className={PrivacyHeadlineStyleH3}>
          Demografische Merkmale bei Google Analytics
        </h3>
        <p>
          Diese Website nutzt die Funktion “demografische Merkmale” von Google
          Analytics. Dadurch können Berichte erstellt werden, die Aussagen zu
          Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese
          Daten stammen aus interessenbezogener Werbung von Google sowie aus
          Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten
          Person zugeordnet werden. Sie können diese Funktion jederzeit über die
          Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die
          Erfassung Ihrer Daten durch Google Analytics wie im Punkt “Widerspruch
          gegen Datenerfassung” dargestellt generell untersagen.
        </p>
        <h3 className={PrivacyHeadlineStyleH3}>Google reCAPTCHA</h3>
        <p>
          Wir nutzen “Google reCAPTCHA” (im Folgenden “reCAPTCHA”) auf unseren
          Websites. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway,
          Mountain View, CA 94043, USA (“Google”). Mit reCAPTCHA soll überprüft
          werden, ob die Dateneingabe auf unseren Websites (z.B. in einem
          Kontaktformular) durch einen Menschen oder durch ein automatisiertes
          Programm erfolgt. Hierzu analysiert reCAPTCHA das Verhalten des
          Websitebesuchers anhand verschiedener Merkmale. Diese Analyse beginnt
          automatisch, sobald der Websitebesucher die Website betritt. Zur
          Analyse wertet reCAPTCHA verschiedene Informationen aus (z.B.
          IP-Adresse, Verweildauer des Websitebesuchers auf der Website oder vom
          Nutzer getätigte Mausbewegungen). Die bei der Analyse erfassten Daten
          werden an Google weitergeleitet. Die reCAPTCHA-Analysen laufen
          vollständig im Hintergrund. Websitebesucher werden nicht darauf
          hingewiesen, dass eine Analyse stattfindet. Die Datenverarbeitung
          erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
          Websitebetreiber hat ein berechtigtes Interesse daran, seine
          Webangebote vor missbräuchlicher automatisierter Ausspähung und vor
          SPAM zu schützen. Weitere Informationen zu Google reCAPTCHA sowie die
          Datenschutzerklärung von Google entnehmen Sie folgenden Links:{" "}
          <a href="https://www.google.com/intl/de/policies/privacy/">
            https://www.google.com/intl/de/policies/privacy/
          </a>{" "}
          und{" "}
          <a href="https://www.google.com/recaptcha/intro/android.html">
            https://www.google.com/recaptcha/intro/android.html
          </a>
          .
        </p>
        <h2 className={PrivacyHeadlineStyle}>Newsletter</h2>
        <h3 className={PrivacyHeadlineStyleH3}>News­letter­daten</h3>
        <p>
          Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten,
          benötigen wir von Ihnen eine E-Mail-Adresse sowie Informationen,
          welche uns die Überprüfung gestatten, dass Sie der Inhaber der
          angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters
          einverstanden sind. Weitere Daten werden nicht bzw. nur auf
          freiwilliger Basis erhoben. Diese Daten verwenden wir ausschließlich
          für den Versand der angeforderten Informationen und geben diese nicht
          an Dritte weiter. Die Verarbeitung der in das
          Newsletteranmeldeformular eingegebenen Daten erfolgt ausschließlich
          auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die
          erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse
          sowie deren Nutzung zum Versand des Newsletters können Sie jederzeit
          widerrufen, etwa über den "Austragen"-Link im Newsletter. Die
          Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt
          vom Widerruf unberührt. Die von Ihnen zum Zwecke des Newsletter-Bezugs
          bei uns hinterlegten Daten werden von uns bis zu Ihrer Austragung aus
          dem Newsletter gespeichert und nach der Abbestellung des Newsletters
          gelöscht. Daten, die zu anderen Zwecken bei uns gespeichert wurden
          (z.B. E-Mail-Adressen für den Mitgliederbereich) bleiben hiervon
          unberührt.
        </p>
        <h2 className={PrivacyHeadlineStyle}>Plugins und Tools</h2>
        <h3 className={PrivacyHeadlineStyleH3}>Youtube</h3>
        <p>
          Unsere Website nutzt Plugins der von Google betriebenen Seite YouTube.
          Betreiber der Seiten ist die YouTube, LLC, 901 Cherry Ave., San Bruno,
          CA 94066, USA. Wenn Sie eine unserer mit einem YouTube-Plugin
          ausgestatteten Seiten besuchen, wird eine Verbindung zu den Servern
          von YouTube hergestellt. Dabei wird dem YouTube-Server mitgeteilt,
          welche unserer Seiten Sie besucht haben. Wenn Sie in Ihrem
          YouTube-Account eingeloggt sind, ermöglichen Sie YouTube, Ihr
          Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies können
          Sie verhindern, indem Sie sich aus Ihrem YouTube-Account ausloggen.
          Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
          Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
          Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Weitere
          Informationen zum Umgang mit Nutzerdaten finden Sie in der
          Datenschutzerklärung von YouTube unter:{" "}
          <a href="https://www.google.de/intl/de/policies/privacy">
            https://www.google.de/intl/de/policies/privacy
          </a>
          .
        </p>
        <h3 className={PrivacyHeadlineStyleH3}>Google Web Fonts*</h3>
        <p>
          Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
          genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf
          einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren
          Browsercache, um Texte und Schriftarten korrekt anzuzeigen. Zu diesem
          Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern
          von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber, dass
          über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von
          Google Web Fonts erfolgt im Interesse einer einheitlichen und
          ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein
          berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
          Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine
          Standardschrift von Ihrem Computer genutzt. Weitere Informationen zu
          Google Web Fonts finden Sie unter{" "}
          <a href="https://developers.google.com/fonts/faq">
            https://developers.google.com/fonts/faq
          </a>{" "}
          und in der Datenschutzerklärung von Google:{" "}
          <a href="https://www.google.com/policies/privacy/">
            https://www.google.com/policies/privacy/
          </a>
          .
        </p>
        <h3 className={PrivacyHeadlineStyleH3}>Google Maps</h3>
        <p>
          Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter
          ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
          94043, USA. Zur Nutzung der Funktionen von Google Maps ist es
          notwendig, Ihre IP Adresse zu speichern. Diese Informationen werden in
          der Regel an einen Server von Google in den USA übertragen und dort
          gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese
          Datenübertragung. Die Nutzung von Google Maps erfolgt im Interesse
          einer ansprechenden Darstellung unserer Online-Angebote und an einer
          leichten Auffindbarkeit der von uns auf der Website angegebenen Orte.
          Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit.
          f DSGVO dar. Mehr Informationen zum Umgang mit Nutzerdaten finden Sie
          in der Datenschutzerklärung von Google:{" "}
          <a href="https://www.google.de/intl/de/policies/privacy">
            https://www.google.de/intl/de/policies/privacy
          </a>
          .
        </p>
        <h3 className={PrivacyHeadlineStyleH3}>OpenStreetMap</h3>
        <p>
          Diese Seite nutzt über eine API den Kartendienst OpenStreetMaps.
          Anbieter ist OpenStreetMap Foundation, 132 Maney Hill Road, Sutton
          Coldfield, West Midlands B72 1JU, United Kingdom. Die Nutzung von
          OpenStreetMap erfolgt im Interesse einer ansprechenden Darstellung
          unserer Online-Angebote und an einer leichten Auffindbarkeit der von
          uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes
          Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Mehr
          Informationen zum Umgang mit Nutzerdaten finden Sie in der
          Datenschutzerklärung von OpenStreetMap:{" "}
          <a href="https://wiki.osmfoundation.org/wiki/Privacy_Policy">
            https://wiki.osmfoundation.org/wiki/Privacy_Policy
          </a>
          .
        </p>
      </div>
    </div>
  </Layout>
)

export default Datenschutzerklaerung
